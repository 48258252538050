import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'next/navigation';
import { SDKResponse } from '@commercetools/frontend-sdk/lib/types/SDKResponse';
import { Category, Money, Product, Variant } from 'shared/types/product';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Gallery, { GalleryImageExtra } from 'components/commercetools-ui/organisms/gallery';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import Alert from 'components/padi-ui/alert';
import AvatarUpload from 'components/padi-ui/avatar-upload';
import { useAddToCartOverlay } from 'context/add-to-cart-overlay';
import { PDP_PRODUCT_ADDED_TO_CART } from 'helpers/constants/events';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import { sdk } from 'sdk';
import { SearchParams } from 'types/next';
import { ProjectConfig } from 'types/project-config';
import { useCart, useAccount } from 'frontastic';
import useTrack from '../hooks/useTrack';

export interface PadiCertCardCatalogProps {
  inModalVersion?: boolean; // Remove.
  searchParams?: SearchParams;
  projectConfig?: ProjectConfig;
  signInAlert?: string;
  noPhotoAlert?: string;
  photoInfoMessage?: string;
  purchaseAsGiftMessage?: string;
  defaultGalleryImages?: Array<StudioImage>;
}

interface StudioImage {
  image: { media: { file: string }; title: string };
}

interface SelectOption {
  label: string;
  value: string;
}

interface PadiCertification {
  cardGroupCode: string;
  cardStockImageUrl: string;
  certificationCode: string;
  certificationDate: string;
  certificationId: number;
  certificationNumber: string;
  credentialName: string;
  name: string;
}

const PadiCertCardCatalog: FC<PadiCertCardCatalogProps> = ({
  inModalVersion,
  signInAlert,
  noPhotoAlert,
  photoInfoMessage,
  purchaseAsGiftMessage,
  defaultGalleryImages,
}) => {
  const { idToken, userData, getUserClubEntitlments } = useGetUserInfo();
  const { loggedIn, getProjectConfig } = useAccount();
  const { addItem } = useCart();
  const { locale } = useParams();
  const galleryRef = useRef<any>();

  const selectCardTypeOptions = [
    { label: 'Physical or eCard', value: 'none' },
    { label: 'Physical', value: 'Physical' },
    { label: 'eCard', value: 'eCard' },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [added, setAdded] = useState(false);
  const [affiliateId, setAffiliateId] = useState('');
  const [userClubEntitlments, setUserClubEntitlments] = useState<string[] | undefined>(undefined);
  const [isClubMember, setIsClubMember] = useState(false);
  const [userCertifications, setUserCertifications] = useState<PadiCertification[] | undefined>(undefined);
  const [selectCertOptions, setSelectCertOptions] = useState<SelectOption[]>([]);
  const [selectCardDesignOptions, setSelectCardDesignOptions] = useState<SelectOption[]>([]);
  const [cardDesigns, setCardDesigns] = useState<Product[]>([]);
  const [galleryImages, setGalleryImages] = useState<GalleryImageExtra[]>([]);
  const [formSelectedCertificateId, setFormSelectedCertificateId] = useState<string>('');
  const [formSelectedCertificateGroupCode, setFormSelectedCertificateGroupCode] = useState<string>('');
  const [formSelectedCertificateName, setFormSelectedCertificateName] = useState<string>('');
  const [formSelectedCardType, setFormSelectedCardType] = useState<string>('none');
  const [formSelectedCardDesign, setFormSelectedCardDesign] = useState<string>('');
  const [variant, setVariant] = useState<Variant>();
  const [product, setProduct] = useState<Product>();
  const [productExcerpt, setProductExcerpt] = useState<string>();
  const [price, setPrice] = useState<Money | undefined>();
  const [certCardCategoryId, setCertCardCategoryId] = useState<string>();
  const [showCardTypeOptions, setShowCardTypeOptions] = useState(false);
  const [showCardDesignOptions, setShowCardDesignOptions] = useState(false);
  const [showAvatarUpload, setShowAvatarUpload] = useState(false);
  const [avatar, setAvatar] = useState<string>();
  const [hasAvatar, setHasAvatar] = useState(false);
  const [shippableCategoryId, setShippableCategoryId] = useState<string>('');
  const [notShippableCategoryId, setNotShippableCategoryId] = useState<string>('');

  const { formatMessage: formatMessagePadi } = useFormat({ name: 'padi' });
  const { formatMessage: formatMessageCart } = useFormat({ name: 'cart' });
  const title = formatMessagePadi({ id: 'certificationCards', defaultMessage: 'Certification Cards' });

  const { trackAddToCart, trackView } = useTrack({ inModalVersion });
  const { show } = useAddToCartOverlay();

  const getCategoryIdBySlug = async (slug: string) => {
    if (!slug) return null;
    const response: SDKResponse<{ items: Category[] }> = await sdk.callAction({
      actionName: '/product/queryCategories',
      query: { whereQuery: `(slug(en="${slug}"))` },
    });
    if (!response.isError) {
      const category = response?.data?.items[0];
      return category;
    }
    return null;
  };

  const getCertCardCategoryId = useCallback(async () => {
    const getCertCardCategory = await getCategoryIdBySlug('certification-card');
    if (getCertCardCategory?.categoryId) {
      setCertCardCategoryId(getCertCardCategory.categoryId);
      return getCertCardCategory.categoryId;
    }
    return null;
  }, []);

  const getShippableCategoryId = useCallback(async () => {
    const shippable = await getCategoryIdBySlug('shippable');
    if (shippable?.categoryId) {
      setShippableCategoryId(shippable.categoryId);
      return shippable.categoryId;
    }
    return null;
  }, []);

  const getNotShippableCategoryId = useCallback(async () => {
    const category = await getCategoryIdBySlug('not-shippable');
    if (category?.categoryId) {
      setNotShippableCategoryId(category.categoryId);
      return category.categoryId;
    }
    return null;
  }, []);

  const getProduct = useCallback(
    async (id: string) => {
      if (!id) return null;
      const response: SDKResponse<Product> = await sdk.callAction({
        actionName: '/product/getProduct',
        query: {
          id,
        },
      });
      if (!response.isError) {
        const product = response?.data;
        setProduct(product);
        setVariant(product.variants[0]);
        setPrice(product.variants[0].price);
        setProductExcerpt(product.variants[0]?.attributes?.['card-excerpt'] ?? '');
        trackView(product);
      }
      return null;
    },
    [trackView],
  );

  const resetProduct = useCallback(() => {
    setShowCardDesignOptions(false);
    setFormSelectedCardDesign('');
    setProduct(undefined);
    setVariant(undefined);
    setPrice(undefined);
    setProductExcerpt('');
  }, []);

  const getSetDefaultGalleryImages = useCallback((images: StudioImage[]) => {
    if (!images.length) return;
    const imageUrls: GalleryImageExtra[] = [];
    images.map((image: StudioImage) => {
      imageUrls.push({
        url: image.image.media.file,
        label: image.image.title,
      });
    });
    setGalleryImages(imageUrls);
  }, []);

  // Populate dropdown with users certificates.
  const updateCertificationOptions = useCallback(async (certifications: PadiCertification[]) => {
    if (!certifications) return;
    const certOptions: SelectOption[] = [{ label: 'Choose certification', value: '' }];
    const nonSellableCerts = [-1, 0, 5, 11, 13, 14, 22, 58, 99, 100, 3391, 4500];
    certifications.forEach((certification) => {
      const certificationId = certification.certificationId;
      const certificationName = certification.credentialName;
      if (!nonSellableCerts.includes(certificationId)) {
        certOptions.push({
          label: certificationName,
          value: `${certificationId}`,
        });
      }
    });
    setSelectCertOptions(certOptions);
  }, []);

  // Get users certificates.
  const getUserCertifications = useCallback(
    async (idToken: string) => {
      if (idToken) {
        const proApiDomain = await getProjectConfig('EXTENSION_PADI_APIS_PRO_API_DOMAIN');
        if (proApiDomain.isError) return;
        const getCerts = await fetch(`${proApiDomain.setting}/certifications/me`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        });
        const certs = await getCerts.json();
        setUserCertifications(certs.items);
        if (certs?.items) {
          updateCertificationOptions(certs.items);
        }
        if (certs?.certificationPhotoUri) setHasAvatar(true);
      }
      getCertCardCategoryId();
    },
    [getCertCardCategoryId, getProjectConfig, updateCertificationOptions],
  );

  // Get users Card designs options.
  const getUserCardDesignOptions = useCallback(
    async (cardType: string, cardGroupCode?: string) => {
      if (!cardType) return;
      const query = {
        categories: certCardCategoryId as string,
        filterCategories: '',
        filterAttributes: '',
        limit: 100,
      };

      switch (cardType) {
        case 'Physical':
          query.filterCategories = shippableCategoryId;
          break;
        case 'eCard':
          query.filterCategories = notShippableCategoryId;
        default:
          break;
      }

      if (formSelectedCertificateGroupCode) {
        query.filterAttributes = `card-groups.key:${cardGroupCode ?? formSelectedCertificateGroupCode}`;
      }

      const response: SDKResponse<{ items: Product[] }> = await sdk.callAction({
        actionName: '/product/query',
        query,
      });
      if (!response.isError) {
        const cardsData = response?.data?.items;
        const cards: Product[] = [];
        const cardOptions: SelectOption[] = [];
        const cardImages: GalleryImageExtra[] = [];
        cardsData.forEach((card: Product) => {
          let showCard = true;
          card.categories?.find((category: Category) => {
            if (category?.key == 'promotion-club-free-ecard' && !isClubMember) {
              showCard = false;
            }
          });

          if (showCard) {
            cards.push(card);
            cardOptions.push({
              label: card.name || '',
              value: card.productId || '',
            });
            if (card.variants[0]) {
              const imagesExtra = card.variants[0]?.imagesExtra;
              imagesExtra?.map((image: GalleryImageExtra) => {
                cardImages.push(image);
              });
            }
          }
        });

        setCardDesigns(cards);
        setSelectCardDesignOptions(cardOptions);
        setShowCardDesignOptions(true);
        setFormSelectedCardDesign(cardOptions[0].value);
        getProduct(cards[0].productId || '');
        setGalleryImages(cardImages);
        setIsLoading(false);
      }
    },
    [
      certCardCategoryId,
      formSelectedCertificateGroupCode,
      getProduct,
      isClubMember,
      notShippableCategoryId,
      shippableCategoryId,
    ],
  );

  // Get users avatar.
  const getUsersAvatar = useCallback(
    async (idToken: string, affiliateId: string) => {
      if (!idToken) return;
      const proApiDomain = await getProjectConfig('EXTENSION_PADI_APIS_PRO_API_DOMAIN');
      if (proApiDomain.isError) return;
      await fetch(`${proApiDomain.setting}/certifications/photos/getphoto`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ affiliateId: affiliateId }),
        method: 'post',
      })
        .then((response) => response.blob())
        .then((data) => {
          const objectUrl = URL.createObjectURL(data);
          if (data?.size !== 0) setAvatar(objectUrl);
        });
    },
    [getProjectConfig],
  );

  const getSetUserClubEntitlements = useCallback(async () => {
    const clubEntitlements = await getUserClubEntitlments();
    setUserClubEntitlments(clubEntitlements);
    if (clubEntitlements && clubEntitlements.length) setIsClubMember(true);
  }, [getUserClubEntitlments]);

  useEffect(() => {
    if (!galleryImages.length && defaultGalleryImages) getSetDefaultGalleryImages(defaultGalleryImages);
    if (idToken && userData) {
      if (!affiliateId) setAffiliateId(userData['custom:affiliate_id']);
      if (userCertifications === undefined) getUserCertifications(idToken);
      if (userClubEntitlments === undefined) getSetUserClubEntitlements();
      getUsersAvatar(idToken, affiliateId ?? userData['custom:affiliate_id']);
    }
    getCertCardCategoryId();
    getShippableCategoryId();
    getNotShippableCategoryId();
  }, [
    affiliateId,
    getCertCardCategoryId,
    getNotShippableCategoryId,
    getSetUserClubEntitlements,
    getShippableCategoryId,
    getUserCertifications,
    getUsersAvatar,
    idToken,
    userCertifications,
    userClubEntitlments,
    userData,
    defaultGalleryImages,
    galleryImages.length,
    getSetDefaultGalleryImages,
  ]);

  const resetForm = () => {
    setFormSelectedCertificateId('');
    setFormSelectedCardType('none');
    setFormSelectedCertificateName('');
    setFormSelectedCertificateGroupCode('');
    setFormSelectedCardDesign('');
    setShowCardTypeOptions(false);
    setIsLoading(false);
    if (defaultGalleryImages) getSetDefaultGalleryImages(defaultGalleryImages);
  };

  // Select user certification.
  const handleCertificationChange = async (e: React.FormEvent) => {
    if (formSelectedCardType !== 'none') {
      setIsLoading(true);
    }
    resetProduct();
    const newValue = (e.target as HTMLSelectElement).value;
    let cardGroupCode = '';

    if (userCertifications) {
      userCertifications.some((cert) => {
        if (cert.certificationId.toString() == newValue) {
          setFormSelectedCertificateName(cert.credentialName);
          setFormSelectedCertificateGroupCode(cert.cardGroupCode);
          cardGroupCode = cert.cardGroupCode;
        }
      });
    }

    setFormSelectedCertificateId(newValue.toString());

    if (newValue != '') {
      if (formSelectedCardType != 'none') {
        await getUserCardDesignOptions(formSelectedCardType, cardGroupCode);
        galleryRef?.current?.handleActiveSlide(0);
      }
      setShowCardTypeOptions(true);
    } else {
      resetForm();
    }
  };

  // Select card type - physical/ecard.
  const handleCardTypeChange = async (e: React.FormEvent) => {
    setIsLoading(true);
    resetProduct();
    const newValue = (e.target as HTMLSelectElement).value;
    setFormSelectedCardType(newValue);
    if (newValue != 'none') {
      await getUserCardDesignOptions(newValue);
      galleryRef?.current?.handleActiveSlide(0);
    }
    setIsLoading(false);
  };

  // Select card design.
  const handleCardDesignChange = (e: React.FormEvent) => {
    const newValue = (e.target as HTMLSelectElement).value;
    setFormSelectedCardDesign(newValue);
    cardDesigns.some((card, index) => {
      if (card.productId == newValue) {
        getProduct(card.productId);
        // Sync gallery image to form option.
        galleryRef?.current?.handleActiveSlide(index);
      }
    });
  };

  // Sync form option to gallery click.
  const galleryActiveIndex = (index: number) => {
    if (cardDesigns.length) {
      setFormSelectedCardDesign(cardDesigns[index]?.productId || '');
      getProduct(cardDesigns[index]?.productId || '');
    }
  };

  const handleAddToCart = async () => {
    setIsLoading(true);

    const custom = {
      type: {
        key: 'certification',
        typeId: 'type',
      },
      fields: {
        certificationId: formSelectedCertificateId,
        certificationName: formSelectedCertificateName,
      },
    };

    if (product && variant) {
      const quantity = 1;
      addItem(product, variant, quantity, custom).then(() => {
        setAdded(true);
        setTimeout(() => {
          setAdded(false);
        }, 1000);

        show(product, variant, quantity);
        setIsLoading(false);
      });

      trackAddToCart(product);
    }
  };

  const avatarUploadIsOpen = (state: boolean) => {
    setShowAvatarUpload(state);
    if (!state && idToken) getUsersAvatar(idToken, affiliateId);
  };

  const handleAvatarUpload = () => {
    setShowAvatarUpload(true);
  };

  const wrapperClassName = inModalVersion
    ? 'md:grid grid-cols-12 pt-70 pb-35 px-20 md:pr-36'
    : 'pt-20 md:pt-24 pb-32 md:grid md:grid-cols-12 md:items-start mx-auto max-w-7xl px-24 lg:px-32';

  const galleryContainerClassName = useClassNames([
    inModalVersion ? 'col-span-6' : 'md:col-span-7 lg:col-span-8',
    'md:pr-26 lg:pr-60 h-auto min-h-200',
  ]);

  const informationContainerClassName = useClassNames([
    inModalVersion ? 'col-span-6' : 'md:col-span-5 lg:col-span-4',
    'mt-24 md:mt-0 h-auto min-h-300',
  ]);

  return (
    <>
      <div className={wrapperClassName}>
        {/* Left Column - Gallery */}
        <div className={galleryContainerClassName}>
          <Gallery
            imagesExtra={galleryImages}
            inModalVersion={inModalVersion}
            firstImagePriority={true}
            syncActiveIndex={galleryActiveIndex}
            ref={galleryRef}
          />
        </div>

        {/* Left Column - Details */}
        <div className={informationContainerClassName}>
          <h1 className="mb-20 text-3xl font-bold tracking-tight text-gray-900">{title}</h1>

          {!loggedIn || !idToken ? (
            <div>
              {signInAlert && <Alert alertType="information" body={signInAlert} accent={true}></Alert>}
              <div className="flex flex-col gap-8 pt-20">
                <Button
                  className="w-full rounded-sm text-14 font-medium"
                  variant="primary"
                  onClick={handleAddToCart}
                  loading={isLoading}
                  added={added}
                  dataAnalytics={PDP_PRODUCT_ADDED_TO_CART}
                  disabled
                >
                  {formatMessageCart({ id: 'cart.add', defaultMessage: 'Add to cart - cards logged out' })}
                </Button>
                <div className="prose mt-10 prose-p:m-0 prose-a:no-underline">
                  {purchaseAsGiftMessage &&
                    (isStringHtml(purchaseAsGiftMessage) ? (
                      <div dangerouslySetInnerHTML={{ __html: purchaseAsGiftMessage }} />
                    ) : (
                      <Markdown className="text-base" markdown={purchaseAsGiftMessage} />
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div>
              {price && (
                <div className="text-2xl font-bold">{CurrencyHelpers.formatForCurrency(price ?? '', locale)}</div>
              )}
              <div className="flex flex-col gap-8 pt-20">
                <Dropdown
                  className="h-full rounded-sm"
                  value={formSelectedCertificateId.toString()}
                  items={selectCertOptions}
                  onChange={handleCertificationChange}
                  disabled={isLoading}
                />
                <Dropdown
                  className="h-full rounded-sm"
                  containerClassName={!showCardTypeOptions ? 'hidden' : ''}
                  value={formSelectedCardType.toString()}
                  items={selectCardTypeOptions}
                  onChange={handleCardTypeChange}
                  disabled={isLoading}
                />
                <Dropdown
                  className="h-full rounded-sm"
                  containerClassName={!showCardDesignOptions ? 'hidden' : ''}
                  value={formSelectedCardDesign.toString()}
                  items={selectCardDesignOptions}
                  onChange={handleCardDesignChange}
                />
                {productExcerpt && (
                  <div className="mt-15 text-sm" dangerouslySetInnerHTML={{ __html: productExcerpt }}></div>
                )}

                <div className="my-10 border-t border-neutral-400">
                  <div className="my-15 text-base font-bold">
                    {formatMessagePadi({ id: 'current.card.photo', defaultMessage: 'Current Card Photo' })}
                  </div>
                  <div className="flex justify-between rounded-md border border-gray-300 bg-blue-100 p-20">
                    <div className="flex flex-col justify-center">
                      {hasAvatar && (
                        <div className="mb-10 aspect-square size-80">
                          {avatar && (
                            <img className="size-full rounded-full object-cover" src={avatar} alt="profile image" />
                          )}
                        </div>
                      )}
                      <div className="w-100">
                        <button className="text-xs font-bold text-padi-blue" type="button" onClick={handleAvatarUpload}>
                          {formatMessagePadi({ id: 'upload.photo', defaultMessage: 'Upload photo' })}
                        </button>
                        <AvatarUpload
                          modalIsOpen={showAvatarUpload}
                          syncModalIsOpen={avatarUploadIsOpen}
                        ></AvatarUpload>
                      </div>
                    </div>
                    {!isLoading && avatar && (
                      <div>
                        {hasAvatar ? (
                          <Alert alertType={'information'} body={photoInfoMessage} accent={false}></Alert>
                        ) : (
                          <Alert alertType={'error'} body={noPhotoAlert} accent={false}></Alert>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <Button
                  className="w-full rounded-sm text-14 font-medium"
                  variant="primary"
                  onClick={handleAddToCart}
                  loading={isLoading}
                  added={added}
                  dataAnalytics={PDP_PRODUCT_ADDED_TO_CART}
                  disabled={!formSelectedCardDesign || !hasAvatar ? true : false}
                >
                  {formatMessageCart({ id: 'cart.add', defaultMessage: 'Add to cart' })}
                </Button>
                <div className="mt-10"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PadiCertCardCatalog;
